import { Button, Dialog, DialogActions, DialogContent, Tooltip, Typography } from '@material-ui/core';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InsertChartRoundedIcon from '@material-ui/icons/InsertChartRounded';
import { MonitoringFrequency } from 'dtos/application';
import { useRiskMonitoringEnabled } from 'hooks/useChartsEnabled';
import { useToasters } from 'hooks/useToasters';
import { useUpdateApplicationSettings } from 'queries/useApplications';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { COLORS } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  disclaimerBadge: {
    border: `1px solid ${COLORS.BLUE_1}`,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
}));

interface Props {
  applicationId: string;
  monitoringFrequency?: MonitoringFrequency;
  className?: string;
  conversionPermitted?: boolean;
}

export const ConvertMonitoringButton: React.FC<Props> = ({
  applicationId,
  monitoringFrequency,
  className,
  conversionPermitted,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();
  const monitoringEnabled = useRiskMonitoringEnabled();

  const [selectedFrequency, setSelectedFrequency] = useState<MonitoringFrequency | undefined>(monitoringFrequency);

  const { mutate, isLoading } = useUpdateApplicationSettings(applicationId, {
    onSuccess: () => {
      showToaster(`Converted to Risk Monitoring successfully.`);
      setIsModalOpen(false);
      navigate(insertIdToRoute(ROUTES.agent.application.MONITORING, applicationId));
    },
    onError: (e) => {
      toasterErrorHandler(e);
      setIsModalOpen(false);
    },
  });

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Tooltip title='Convert to Risk Monitoring'>
        <Button
          className={className}
          onClick={handleModalOpen}
          variant='outlined'
          color='default'
          disabled={!conversionPermitted}
          startIcon={<InsertChartRoundedIcon />}
        >
          Monitor
        </Button>
      </Tooltip>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogContent>
          <Typography variant='h3' className={classes.title}>
            Confirm Enable Risk Monitoring
          </Typography>
          <Typography variant='body1'>
            Risk Monitoring will be enabled on this Risk Assessment. This will periodically remove existing data and
            schedule rescan on all devices to automatically generate history of risk data.
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id='select-monitoring-frequency'>Select Frequency</InputLabel>
              <Select
                disabled={isLoading}
                labelId='select-monitoring-frequency'
                value={selectedFrequency}
                onChange={(el) => {
                  setSelectedFrequency(el.target.value as MonitoringFrequency);
                }}
                label='Select Frequency'
              >
                {Object.values(MonitoringFrequency).map((frequency) => (
                  <MenuItem key={frequency} value={frequency}>
                    {frequency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <br />
            The first schedule will start right now after converting. Do you want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>{conversionPermitted ? 'Cancel' : 'Close'}</Button>
          {!monitoringEnabled ? (
            <div>
              <SecurityCard.Badge variant='locked' className={classes.disclaimerBadge}>
                Please upgrade account to convert. Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a>
              </SecurityCard.Badge>
            </div>
          ) : (
            <Button
              variant='contained'
              color='primary'
              disabled={isLoading}
              onClick={() => mutate({ monitoringEnabled: true, monitoringFrequency: selectedFrequency })}
              startIcon={<InsertChartRoundedIcon />}
            >
              {isLoading ? 'Submitting...' : 'Confirm'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

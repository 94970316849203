import { makeStyles, Theme } from '@material-ui/core';
import { SecurityScanDTO } from 'dtos/application';
import { SecurityFindingBySlugDTO, SecurityScanType } from 'dtos/security-finding';
import { useExternalScanFindings } from 'hooks/external-scan/useExternalScanFindings';
import { useExternalScanGrades } from 'hooks/external-scan/useExternalScanGrades';
import { useExternalScanPreviousFindings } from 'hooks/external-scan/useExternalScanPreviousFindings';
import { useWindowSize } from 'hooks/useWindowSize';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createSecurityRoute } from 'telivy-constants';

import { SourceRoute } from '..';
import { ExternalScanItem, Props as ExternalScanItemProps } from '../components/ExternalScanItem';

interface StyleProps {
  tile?: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: ({ tile }) => ({
    display: 'grid',
    gridTemplateColumns: tile ? 'repeat(2, 1fr)' : '1fr',
    gap: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  }),
}));

export const SCAN_TYPE_NAME: { [key in SecurityScanType]: string } = {
  [SecurityScanType.APPLICATION_SECURITY]: 'Application Security',
  [SecurityScanType.HACKER_CHATTER]: 'Dark Web Findings',
  [SecurityScanType.DNS_HEALTH]: 'DNS Health',
  [SecurityScanType.ENDPOINT_SECURITY]: 'Endpoint Security',
  [SecurityScanType.IP_REPUTATION]: 'IP Reputation',
  [SecurityScanType.NETWORK_SECURITY]: 'Network Security',
  [SecurityScanType.SOCIAL_ENGINEERING]: 'Social Engineering',
  [SecurityScanType.PATCHING_CADENCE]: 'External Vulnerabilities',
  [SecurityScanType.INSURABILITY]: 'Insurability',
};

export interface SingleScanInfo {
  title: ValueOf<typeof SCAN_TYPE_NAME>;
  description: string;
  section: SecurityScanType;
  errorMessage: string;
}

export type ScanInfo = {
  [key in SecurityScanType]: SingleScanInfo;
};

export const SCAN_INFO: ScanInfo = {
  [SecurityScanType.SOCIAL_ENGINEERING]: {
    title: SCAN_TYPE_NAME[SecurityScanType.SOCIAL_ENGINEERING],
    description: 'Emails of employees exposed on the internet.',
    section: SecurityScanType.SOCIAL_ENGINEERING,
    errorMessage: 'There was an error scanning social engineering.',
  },
  [SecurityScanType.HACKER_CHATTER]: {
    title: SCAN_TYPE_NAME[SecurityScanType.HACKER_CHATTER],
    description: 'Breaches for emails in your domain.',
    section: SecurityScanType.HACKER_CHATTER,
    errorMessage: 'There was an error scanning dark web.',
  },
  [SecurityScanType.NETWORK_SECURITY]: {
    title: SCAN_TYPE_NAME[SecurityScanType.NETWORK_SECURITY],
    description: 'Insecure network configurations and settings.',
    section: SecurityScanType.NETWORK_SECURITY,
    errorMessage: 'There was an error scanning external network.',
  },
  [SecurityScanType.ENDPOINT_SECURITY]: {
    title: SCAN_TYPE_NAME[SecurityScanType.ENDPOINT_SECURITY],
    description: 'Unprotected entry points of user tools.',
    section: SecurityScanType.ENDPOINT_SECURITY,
    errorMessage: 'There was an error scanning endpoint security.',
  },
  [SecurityScanType.APPLICATION_SECURITY]: {
    title: SCAN_TYPE_NAME[SecurityScanType.APPLICATION_SECURITY],
    description: 'Common website and application vulnerabilities.',
    section: SecurityScanType.APPLICATION_SECURITY,
    errorMessage: 'There was an error scanning application security.',
  },
  [SecurityScanType.DNS_HEALTH]: {
    title: SCAN_TYPE_NAME[SecurityScanType.DNS_HEALTH],
    description: 'The health of your domain name server.',
    section: SecurityScanType.DNS_HEALTH,
    errorMessage: 'There was an error scanning dns health.',
  },
  [SecurityScanType.IP_REPUTATION]: {
    title: SCAN_TYPE_NAME[SecurityScanType.IP_REPUTATION],
    description: 'Suspicious activity, such as malware or spam, from your IPs.',
    section: SecurityScanType.IP_REPUTATION,
    errorMessage: 'There was an error scanning ip reputation.',
  },
  [SecurityScanType.PATCHING_CADENCE]: {
    title: SCAN_TYPE_NAME[SecurityScanType.PATCHING_CADENCE],
    description: 'Exposures and risks detected on your external assets.',
    section: SecurityScanType.PATCHING_CADENCE,
    errorMessage: 'There was an error scanning external vulnerabilities.',
  },
  [SecurityScanType.INSURABILITY]: {
    title: SCAN_TYPE_NAME[SecurityScanType.INSURABILITY],
    description: 'Factors affecting your ability to get cyber insurance',
    section: SecurityScanType.INSURABILITY,
    errorMessage: 'There was an error calculating your insurability.',
  },
};

interface Props {
  securityScan: SecurityScanDTO;
  applicationId: string;
  tile?: boolean;
  sourceRoute: SourceRoute;
  securityScanId: string;
}

interface QueryParams {
  section?: SecurityScanType;
}

export const SecurityExternalScan = ({ applicationId, tile, sourceRoute, securityScan, securityScanId }: Props) => {
  const classes = useStyles({ tile });
  const { width: windowWidth } = useWindowSize();
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(() => queryString.parse(search) as unknown as QueryParams, [search]);

  const {
    allSubdomains,
    allSubdomainsIPAddresses,
    allDomains,
    findingsByType,
    isErrorPatchingCadence,
    isErrorScanDns,
    isErrorScanEmail,
    isErrorScanIpReputation,
    isErrorScanNetwork,
    isLoadingPatchingCadence,
    isLoadingScanDns,
    isLoadingScanEmail,
    isLoadingScanIpReputation,
    isLoadingScanNetwork,

    scannedSubdomainsSocialEngineering,
    scannedSubdomainsIpReputation,
    scannedSubdomainsNetwork,
    scannedSubdomainsApplicationSecurity,
    scannedSubdomainsDnsHealth,
    scannedSubdomainsPatchingCadence,
  } = useExternalScanFindings({ securityScan });

  const { previousFindingsByType } = useExternalScanPreviousFindings({ securityScan });

  const { getGrateByType } = useExternalScanGrades({ securityScan });

  const handleClick = (section: SecurityScanType) => {
    navigate(
      `${createSecurityRoute(sourceRoute.security.EXTERNAL_SCAN, applicationId, securityScanId)}?section=${section}`,
    );
  };

  const getFindingUrl = (finding: SecurityFindingBySlugDTO) =>
    createSecurityRoute(sourceRoute.security.FINDING, applicationId, securityScanId, finding.slug);

  const commonProps: Pick<
    ExternalScanItemProps,
    'tile' | 'onClick' | 'previousScanAt' | 'getFindingUrl' | 'allSubdomains'
  > = {
    tile,
    previousScanAt: securityScan.previousScan?.updatedAt,
    onClick: handleClick,
    getFindingUrl,
    allSubdomains,
  };

  return (
    <div className={classes.root}>
      {/* {(!isErrorScanEmail || securityScan.previousScan?.emailScan?.breaches) && (
        <ExternalScanItem
          {...commonProps}
          grade={getGrateByType(SecurityScanType.HACKER_CHATTER)}
          findings={findingsByType[SecurityScanType.HACKER_CHATTER]}
          previousFindings={previousFindingsByType[SecurityScanType.HACKER_CHATTER] || undefined}
          isError={isErrorScanEmail}
          scanInfo={SCAN_INFO.breaches}
          isDefaultExpanded={queryParams.section === SCAN_INFO.breaches.section}
          isLoading={isLoadingScanEmail}
        />
      )} */}

      {(!isErrorScanEmail || securityScan.previousScan?.emailScannedAt) && (
        <ExternalScanItem
          {...commonProps}
          grade={getGrateByType(SecurityScanType.SOCIAL_ENGINEERING)}
          findings={findingsByType[SecurityScanType.SOCIAL_ENGINEERING]}
          previousFindings={previousFindingsByType[SecurityScanType.SOCIAL_ENGINEERING] || undefined}
          isError={isErrorScanEmail}
          scanInfo={SCAN_INFO.socialEngineering}
          isDefaultExpanded={queryParams.section === SCAN_INFO.socialEngineering.section}
          isLoading={isLoadingScanEmail}
          scannedSubdomains={scannedSubdomainsSocialEngineering}
          allSubdomains={allDomains}
        />
      )}

      {(!isErrorScanNetwork || securityScan.previousScan?.networkScannedAt) && (
        <ExternalScanItem
          {...commonProps}
          grade={getGrateByType(SecurityScanType.NETWORK_SECURITY)}
          findings={findingsByType[SecurityScanType.NETWORK_SECURITY]}
          previousFindings={previousFindingsByType[SecurityScanType.NETWORK_SECURITY] || undefined}
          isError={isErrorScanNetwork}
          scanInfo={SCAN_INFO.networkScan}
          isDefaultExpanded={queryParams.section === SCAN_INFO.networkScan.section}
          isLoading={isLoadingScanNetwork}
          scannedSubdomains={scannedSubdomainsNetwork}
          allSubdomains={allSubdomainsIPAddresses}
        />
      )}

      {/* {(!isErrorScanNetwork || securityScan.previousScan?.networkScannedAt) && (
        <ExternalScanItem
          {...commonProps}
          grade={getGrateByType(SecurityScanType.ENDPOINT_SECURITY)}
          isError={isErrorScanNetwork}
          findings={findingsByType[SecurityScanType.ENDPOINT_SECURITY]}
          previousFindings={previousFindingsByType[SecurityScanType.ENDPOINT_SECURITY] || undefined}
          scanInfo={SCAN_INFO.endpointSecurity}
          isDefaultExpanded={queryParams.section === SCAN_INFO.endpointSecurity.section}
          isLoading={isLoading || isLoadingScanNetwork}
          scannedSubdomains={scannedSubdomainsEndpointSecurity}
        />
      )} */}

      {(!isErrorScanNetwork || securityScan.previousScan?.networkScannedAt) && (
        <ExternalScanItem
          {...commonProps}
          grade={getGrateByType(SecurityScanType.APPLICATION_SECURITY)}
          findings={findingsByType[SecurityScanType.APPLICATION_SECURITY]}
          previousFindings={previousFindingsByType[SecurityScanType.APPLICATION_SECURITY] || undefined}
          isError={isErrorScanNetwork}
          scanInfo={SCAN_INFO.applicationSecurity}
          isDefaultExpanded={queryParams.section === SCAN_INFO.applicationSecurity.section}
          isLoading={isLoadingScanNetwork}
          scannedSubdomains={scannedSubdomainsApplicationSecurity}
          allSubdomains={allSubdomainsIPAddresses}
        />
      )}

      {(!isErrorScanDns || securityScan.previousScan?.dnsScannedAt) && (
        <ExternalScanItem
          {...commonProps}
          grade={getGrateByType(SecurityScanType.DNS_HEALTH)}
          findings={findingsByType[SecurityScanType.DNS_HEALTH]}
          previousFindings={previousFindingsByType[SecurityScanType.DNS_HEALTH] || undefined}
          isError={isErrorScanDns}
          scanInfo={SCAN_INFO.dnsHealth}
          isDefaultExpanded={queryParams.section === SCAN_INFO.dnsHealth.section}
          isLoading={isLoadingScanDns}
          scannedSubdomains={scannedSubdomainsDnsHealth}
          allSubdomains={allDomains}
        />
      )}

      {(!isErrorScanIpReputation || securityScan.previousScan?.ipReputationScannedAt) && (
        <ExternalScanItem
          {...commonProps}
          grade={getGrateByType(SecurityScanType.IP_REPUTATION)}
          findings={findingsByType[SecurityScanType.IP_REPUTATION]}
          previousFindings={previousFindingsByType[SecurityScanType.IP_REPUTATION] || undefined}
          isError={isErrorScanIpReputation}
          scanInfo={SCAN_INFO.ipReputation}
          isDefaultExpanded={queryParams.section === SCAN_INFO.ipReputation.section}
          isLoading={isLoadingScanIpReputation}
          scannedSubdomains={scannedSubdomainsIpReputation}
          allSubdomains={allSubdomainsIPAddresses}
          noBottomBorder={tile && windowWidth >= 960}
        />
      )}

      {(!isErrorPatchingCadence || securityScan.previousScan?.patchingCadenceScannedAt) && (
        <ExternalScanItem
          {...commonProps}
          grade={getGrateByType(SecurityScanType.PATCHING_CADENCE)}
          findings={findingsByType[SecurityScanType.PATCHING_CADENCE]}
          previousFindings={previousFindingsByType[SecurityScanType.PATCHING_CADENCE] || undefined}
          isError={isErrorPatchingCadence}
          scanInfo={SCAN_INFO.patchingCadence}
          isDefaultExpanded={queryParams.section === SCAN_INFO.patchingCadence.section}
          noBottomBorder={true}
          isLoading={isLoadingPatchingCadence}
          scannedSubdomains={scannedSubdomainsPatchingCadence}
          allSubdomains={allSubdomainsIPAddresses}
        />
      )}
    </div>
  );
};

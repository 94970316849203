import { makeStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { EmptyState } from 'components/EmptyState';
// import { LoadingContainer } from 'components/LoadingContainer';
import { Option, RadioSelect } from 'components/RadioSelect';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { DataRiskDistributionDTO, DeepScanDTO, DeepScanNetworkHostDTO, DeepScanTargetDTO } from 'dtos/deep-scan';
// import { useExternalScanDisabled } from 'hooks/useAccountDisabled';
// import { useChartsEnabled } from 'hooks/useChartsEnabled';
// import { uniq } from 'lodash';
import uniqBy from 'lodash/uniqBy';
// import { useCurrentAgent } from 'queries/useAgent';
// import { useApplicationSecurityScan } from 'queries/useApplications';
// import { useDeepScan, useDeepScanNetworkHosts, useDeepScanTargets } from 'queries/useDeepScan';
import React from 'react';
// import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createSecurityRoute } from 'telivy-constants';
import { isScanApplication } from 'telivy-selectors';

import { ApplicationViewContainer } from '../../ApplicationViewContainer';
import { InventoryData } from './Data';
import { InventoryDevices } from './Devices';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  table: {
    width: '100%',
  },

  selectsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  select: {},
}));

export type SourceRoute = {
  PARENT: string;
  ROOT: string;
  DEVICES: string;
  USERS: string;
  DATA: string;
};

interface Props {
  application: ApplicationWithAgentDTO;
  deepScan: DeepScanDTO | undefined;
  deepScanNetworkHosts: DeepScanNetworkHostDTO[] | undefined;
  deepScanTargets: DeepScanTargetDTO[];
  sourceRoute: SourceRoute;
  totalDataRiskDistribution?: DataRiskDistributionDTO[];
  isLoadingDataRisk?: boolean;
}

export const InventoryView = ({
  application,
  deepScan,
  deepScanNetworkHosts,
  deepScanTargets,
  sourceRoute,
  totalDataRiskDistribution,
  isLoadingDataRisk,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const viewOptions: Option<ValueOf<SourceRoute>>[] = React.useMemo(() => {
    const options = [];

    // if (isAgentAnAdmin(agent)) {
    options.push({
      label: `Devices (${deepScanNetworkHosts ? uniqBy(deepScanNetworkHosts, 'ipAddress').length : 0})`,
      value: createSecurityRoute(sourceRoute.DEVICES, application.id),
    });

    options.push({
      label: `Users (${0})`,
      value: createSecurityRoute(sourceRoute.USERS, application.id),
    });

    options.push({
      label: `Data (${totalDataRiskDistribution?.length || 0})`,
      value: createSecurityRoute(sourceRoute.DATA, application.id),
    });
    // }

    return options;
  }, [sourceRoute, application, deepScanNetworkHosts, totalDataRiskDistribution]);

  if (!isScanApplication(application)) {
    return (
      <ApplicationViewContainer>
        <EmptyState
          title='Not enabled'
          text={
            <>
              Please reach out to <a href='mailto:brokerage@telivy.com'>brokerage@telivy.com</a> for access.
            </>
          }
          icon={<LockOutlinedIcon />}
        />
      </ApplicationViewContainer>
    );
  }

  // if (isLoading) {
  //   return <LoadingContainer />;
  // }

  return (
    <div className={classes.root}>
      <div className={classes.selectsContainer}>
        <RadioSelect<ValueOf<SourceRoute>>
          responsive
          options={viewOptions}
          className={classes.select}
          selectedValue={location.pathname}
          onOptionSelect={(opt) => navigate(opt.value)}
          selectedFn={(optionValue, selectedValue) => (selectedValue ? selectedValue.includes(optionValue) : false)}
        />
      </div>

      <Routes>
        <Route
          path={sourceRoute.DEVICES}
          element={<InventoryDevices deepScanTargets={deepScanTargets} deepScanNetworkHosts={deepScanNetworkHosts} />}
        />

        <Route
          path={sourceRoute.DATA}
          element={
            <InventoryData
              deepScan={deepScan}
              totalDataRiskDistribution={totalDataRiskDistribution}
              isLoadingDataRisk={isLoadingDataRisk}
            />
          }
        />

        <Route index element={<Navigate replace to={sourceRoute.DEVICES} />} />
      </Routes>
    </div>
  );
};

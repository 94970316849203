import { Button, makeStyles } from '@material-ui/core';
import cx from 'classnames';
// import { Modal } from 'components/Modal';
import { ApplicationStatus, ApplicationWithAgentDTO } from 'dtos/application';
import { DeepScanDTO } from 'dtos/deep-scan';
// import { usePassphraseEnabled } from 'hooks/usePassphraseEnabled';
import { ReactComponent as AppleLogo } from 'images/apple.svg';
import { ReactComponent as WindowsLogo } from 'images/windows.svg';
// import { useCurrentAgent } from 'queries/useAgent';
import { useDeepScanTargets } from 'queries/useDeepScan';
import React, { useCallback, useState } from 'react';
import {
  INSTALL_AGENTS_EXE_URL,
  INSTALL_AGENTS_MAC_ONE_LINER,
  INSTALL_AGENTS_MSI_URL,
  INSTALL_AGENTS_ONE_LINER,
  SCAN_BROWSER_PASSWORDS_MAC_ONE_LINER,
  // SUPPORT_URL,
} from 'telivy-constants';
// import { isAgentAnAdmin } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
// import { PassPhraseSwitch } from 'templates/SecurityAssessments/components/PassPhraseSwitch';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';
import { RequestForConfigurationButton } from 'views/agent/views/application-details/views/security/RequestForConfigurationButton';

import { CopyDeepScanIdButton } from './CopyDeepScanIdButton';
import { CONFIGURATION_STEP } from './index';
import { SectionTemplate } from './SectionTemplate';

const useStyles = makeStyles((theme) => ({
  boxesContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  box: {
    flex: 1,
    height: '100%',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
  boxTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    fontSize: 16,
  },
  boxDescription: {
    fontSize: 14,
    margin: 0,
  },
  boxSubTitle: {
    fontWeight: 'bold',
    fontSize: 12,
    marginTop: theme.spacing(2),
  },
  boxButton: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  boxButtonMac: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  or: {
    fontWeight: 'bold',
  },

  badge: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'left',
  },
  badge2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'left',
    justifyContent: 'flex-start',
    '& div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
    },
  },
  select: {
    marginBottom: theme.spacing(2),
  },

  osContainer: {
    display: 'flex',
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  osItem: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    cursor: 'pointer',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    color: COLORS.GREY_1,
    backgroundColor: COLORS.GREY_6,
    borderRadius: theme.spacing(1.5),
    display: 'flex',
    gap: theme.spacing(0.5),
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    transition: 'all .2s linear',
    minWidth: theme.spacing(12.5),
    boxSizing: 'border-box',

    '&:not($osActive):hover': {
      backgroundColor: COLORS.GREY_4,
    },
  },
  icon: {
    '& path': {
      fill: '#BFC1D2',
    },
  },
  osActive: {
    backgroundColor: COLORS.BLUE_1,
    color: COLORS.WHITE,
    '& $icon path': {
      fill: COLORS.WHITE,
    },
  },

  downloadBox: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'start',

    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 'inherit',
    },
  },
  copyButton: {},
  copyInstallButton: {
    marginBottom: theme.spacing(3),
  },
  oneLiner: {
    padding: theme.spacing(1),
    backgroundColor: COLORS.GREY_4,
    fontFamily: 'monospace',
  },
  fullDiskImage: {
    width: '100%',
  },

  passPhraseFormContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },

  passPhraseForm: {
    alignSelf: 'end',
    marginLeft: theme.spacing(1),
  },

  noMargin: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

enum OS {
  WINDOWS = 'Windows',
  MAC = 'Mac',
}

interface Props {
  deepScan: DeepScanDTO;
  application: ApplicationWithAgentDTO;
  onVerify: () => void;
}

const INSTALL_ACTIFILE_MAC_ONE_LINER =
  "curl -fsSL 'https://install.telivy.com/actifile-helper.sh' | bash -s -- -s install ACTIFILE_INSTALL_KEY";

export const InstallAgentsSection = ({ deepScan, application, onVerify }: Props) => {
  const classes = useStyles();
  // const [copyPassphrase, setCopyPassphrase] = useState(false);

  const isMacDefault =
    new Date(application.createdAt).getFullYear() >= 2024 &&
    (deepScan.actifileUninstallKey || application.applicationStatus == ApplicationStatus.CONFIGURATION_PENDING);

  const [selected, setSelected] = useState(isMacDefault ? OS.MAC : OS.WINDOWS);

  const { data: deepScanTargets, isLoading: isTargetsLoading } = useDeepScanTargets(deepScan.id, {
    enabled: Boolean(deepScan.id),
  });

  // const { data: agent } = useCurrentAgent();
  const macAgentsIds = deepScan.actifileID;

  // const passPhraseStored = useMemo(() => getDeepScanPassPhrase(deepScan.id), [deepScan]);
  // const [passPhrase, setPassPhrase] = useState(passPhraseStored);

  // const enablePassphrase = usePassphraseEnabled();

  const renderContent = useCallback(() => {
    const orgName = application.applicationResponse.organization_name
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');

    switch (selected) {
      case OS.WINDOWS:
        if (isMacDefault) {
          return (
            <SecurityCard.Badge variant='warning' className={classes.badge2}>
              Mac support is enabled for this assessment. Please note that Mac and Windows assessments should be
              separate since Mac support is in Beta.
            </SecurityCard.Badge>
          );
        }

        return (
          <>
            {/*<SecurityCard.Badge variant='warning' className={classes.badge}>
              Note: If there is an active Anti Virus, then allow installing &quot;Telivy Agent&quot; software and
              whitelist &quot;C:\Program Files (x86)\Telivy\Telivy Agent&quot; path. Please refer to our{' '}
              <a href={SUPPORT_URL} target='_blank' rel='noreferrer'>
                documentation
              </a>{' '}
              for more details.
            </SecurityCard.Badge>*/}

            <div className={classes.box}>
              <div className={classes.boxTitle}>Supported Versions:</div>
              <p className={classes.boxDescription}>
                Windows 10 64-bit (Enterprise, Pro, Home, Education)
                <br />
                Windows 11 64-bit (Enterprise, Pro, Home, Education)
                <br />
                Windows Server 2016
                <br />
                Windows Server 2019
                <br />
                Windows Server 2022
              </p>
            </div>

            {/*(enablePassphrase || passPhrase) && (
              <div className={classes.boxesContainer}>
                <div className={classes.box}>
                  <div className={classes.boxTitle}>Full Password Capture (End-to-End Encrypted):</div>
                  <p className={classes.boxDescription}>
                    By default, we capture only masked passwords from browsers.
                    <br />
                    <br />
                    Enabling this feature allows you to securely capture full passwords from browsers by using a
                    passphrase to encrypt it end-to-end. The passphrase is saved only on this device, and is not sent to
                    Telivy. The captured full passwords can be decrypted only on this device or by knowing the
                    passphrase.
                  </p>

                  <div className={classes.passPhraseFormContainer}>
                    <PassPhraseSwitch
                      deepScanId={deepScan.id}
                      className={classes.passPhraseForm}
                      onChange={(el) => setPassPhrase(el)}
                    />
                    {passPhrase && (
                      <Modal openButton={<Button>Show Passphrase</Button>} title='Current Passphrase'>
                        <p className={classes.oneLiner}>{passPhrase}</p>
                      </Modal>
                    )}
                  </div>
                </div>
              </div>
            )*/}

            <div className={classes.boxesContainer}>
              <div className={classes.box}>
                <div className={classes.boxTitle}>Option 1: Agentless Deployment</div>
                <p className={classes.boxDescription}>
                  <SecurityCard.Badge variant='info' className={classes.badge2}>
                    No agents installed, no admin access required. No uninstall needed.
                  </SecurityCard.Badge>
                  Download and run the scanner or email the below instructions to employees.
                  <br />
                  The scanner will run as Administrator if the user has admin privileges, otherwise it will run as
                  non-admin.
                </p>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.boxButton}
                  href={INSTALL_AGENTS_EXE_URL.replace('TELIVY_KEY', deepScan.id).replace('ORG_NAME', orgName)}
                  target='_blank'
                >
                  Download Telivy Scanner (.exe)
                </Button>
                <CopyDeepScanIdButton
                  deepScanId={INSTALL_AGENTS_EXE_URL.replace('TELIVY_KEY', deepScan.id).replace('ORG_NAME', orgName)}
                  className={classes.boxButton}
                  label='Copy Link to Download'
                  title='Agent Link'
                />

                <div className={classes.boxDescription}>
                  <i>
                    <br />
                    Instructions for employees:
                  </i>
                  <ul className={classes.noMargin}>
                    <li>
                      Download the software from the{' '}
                      <a href={INSTALL_AGENTS_EXE_URL.replace('TELIVY_KEY', deepScan.id).replace('ORG_NAME', orgName)}>
                        link
                      </a>
                      .
                    </li>
                    <li>Double click to run it.</li>
                    {/*<li>If Anti Virus blocks it while running, allow it by clicking on Run Anyways.</li>*/}
                    <li>When prompted for admin access, try clicking Yes. If it gives an error, you can click No.</li>
                  </ul>
                </div>

                <div className={classes.boxTitle}>
                  <br />
                  <br />
                  Option 2: Agent Deployment
                </div>
                <p className={classes.boxDescription}>
                  <SecurityCard.Badge variant='info' className={classes.badge2}>
                    Agents installed, admin access required. Supports one-click rescan and uninstall.
                  </SecurityCard.Badge>
                  Download and run the installer as Administrator or follow instructions for RMM.
                </p>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.boxButton}
                  href={INSTALL_AGENTS_MSI_URL.replace('TELIVY_KEY', deepScan.id).replace('ORG_NAME', orgName)}
                  target='_blank'
                >
                  Download Telivy Installer (.msi)
                </Button>
                <CopyDeepScanIdButton
                  deepScanId={INSTALL_AGENTS_MSI_URL.replace('TELIVY_KEY', deepScan.id).replace('ORG_NAME', orgName)}
                  className={classes.boxButton}
                  label='Copy Link to Download'
                  title='Agent Installer Link'
                />

                <div className={classes.boxDescription}>
                  <i>
                    <br />
                    Instructions for RMM:
                  </i>
                  <ul className={classes.noMargin}>
                    <li>
                      Use the Powershell terminal or the Execute Script option in RMM to run the following command as
                      Administrator or System User.
                    </li>
                  </ul>
                </div>

                {/*<SecurityCard.Badge
                  variant='success'
                  className={classes.downloadBox}
                  onMouseEnter={() => setCopyPassphrase(true)}
                  onMouseLeave={() => setCopyPassphrase(false)}
                >
                  Your Telivy Key is {deepScan.id}
                  {passPhrase ? `:${copyPassphrase ? passPhrase : passPhrase.replace(/./g, '*')}` : null}
                  <CopyDeepScanIdButton
                    deepScanId={deepScan.id + (passPhrase ? `:${passPhrase}` : '')}
                    className={classes.copyButton}
                    label='Copy'
                  />
                </SecurityCard.Badge>*/}

                {/*<div className={classes.boxTitle}>
                  <br />
                  <br />
                  OR Run as Administrator via Powershell/RMM:
                </div>
                <p className={classes.boxDescription}>
                  Run the following command as Administrator on powershell terminal.
                  <br />
                  OR via RMM as System User.
                </p>*/}
                <p
                  className={classes.oneLiner}
                  /*onMouseEnter={() => setCopyPassphrase(true)}
                  onMouseLeave={() => setCopyPassphrase(false)}*/
                >
                  &lt;# Install Telivy for {application.applicationResponse.organization_name} #&gt;&nbsp;
                  {INSTALL_AGENTS_ONE_LINER.replace(/<TELIVY_KEY>/g, deepScan.id).replace(/\[dollar\]/g, '$')}
                </p>

                <Button
                  variant='contained'
                  color='primary'
                  className={classes.boxButtonMac}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `<# Install Telivy for ${application.applicationResponse.organization_name} #> ` +
                        INSTALL_AGENTS_ONE_LINER.replace(/<TELIVY_KEY>/g, deepScan.id).replace(/\[dollar\]/g, '$'),
                    );
                  }}
                >
                  Copy Powershell Command
                </Button>

                <CopyDeepScanIdButton
                  deepScanId={deepScan.id}
                  className={classes.boxButtonMac}
                  label='Copy Telivy Key'
                />
              </div>
            </div>
          </>
        );
      case OS.MAC:
        if (!macAgentsIds) {
          return (
            <>
              <SecurityCard.Badge variant='warning' className={classes.badge2}>
                Please note that Mac support is in Beta and not all features are available. Mac and Windows assessments
                should be separate since Mac support is in Beta.
              </SecurityCard.Badge>

              {(isTargetsLoading || (deepScanTargets || []).length > 0) && (
                <SecurityCard.Badge variant='info' className={classes.badge2}>
                  <span>
                    Please request configuration on a new empty assessment if you need to configure on mac devices.
                  </span>
                </SecurityCard.Badge>
              )}

              {!(isTargetsLoading || (deepScanTargets || []).length > 0) && (
                <SecurityCard.Badge variant='info' className={classes.badge2}>
                  <span>Please request configuration if you need to configure on mac devices.</span>
                  <RequestForConfigurationButton
                    applicationId={application.id}
                    isRequestedForConfiguration={
                      application.applicationStatus == ApplicationStatus.CONFIGURATION_PENDING
                    }
                    requestPermitted={application.applicationStatus != ApplicationStatus.CONFIGURATION_PENDING}
                  />
                </SecurityCard.Badge>
              )}
            </>
          );
        }

        return (
          <>
            <SecurityCard.Badge variant='warning' className={classes.downloadBox}>
              Please note that Mac support is in Beta and not all features are available. Mac and Windows assessments
              should be separate since Mac support is in Beta.
            </SecurityCard.Badge>
            <div className={classes.box}>
              <div className={classes.boxTitle}>Supported Versions:</div>
              <p className={classes.boxDescription}>macOS 11 Big Sur and higher</p>
            </div>

            <div className={classes.boxesContainer}>
              <div className={classes.box}>
                <div className={classes.boxTitle}>Run as System User:</div>
                <p className={classes.boxDescription}>
                  Run the following command on Terminal
                  <br />
                  OR just use your RMM to run the script as System User.
                </p>

                <p className={classes.oneLiner}>{INSTALL_AGENTS_MAC_ONE_LINER.replace('TELIVY_KEY', deepScan.id)}</p>

                <Button
                  variant='contained'
                  color='primary'
                  className={classes.copyInstallButton}
                  onClick={() => {
                    navigator.clipboard.writeText(INSTALL_AGENTS_MAC_ONE_LINER.replace('TELIVY_KEY', deepScan.id));
                  }}
                >
                  Copy Install Command 1
                </Button>

                {/*<p className={classes.boxDescription}>
                  Run the following command on Terminal
                  <br />
                  OR just use your RMM to run the script as System User.
                </p>*/}

                <p className={classes.oneLiner}>
                  {INSTALL_ACTIFILE_MAC_ONE_LINER.replace('ACTIFILE_INSTALL_KEY', deepScan.actifileID || '')}
                </p>

                <Button
                  variant='contained'
                  color='primary'
                  className={classes.copyInstallButton}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      INSTALL_ACTIFILE_MAC_ONE_LINER.replace('ACTIFILE_INSTALL_KEY', deepScan.actifileID || ''),
                    );
                  }}
                >
                  Copy Install Command 2
                </Button>

                <div className={classes.boxTitle}>Grant Permissions:</div>
                <p className={classes.boxDescription}>
                  Open System Preferences -&gt; Privacy and Security -&gt; Full Disk Access
                  <br />
                  And allow access to <strong>afmond</strong>
                </p>

                <p className={classes.oneLiner}>
                  <img
                    src='https://install.telivy.com/actifile-full-disk-access.png'
                    alt='Full Disk Access'
                    className={classes.fullDiskImage}
                  />
                </p>

                <p className={classes.boxDescription}>
                  Open System Preferences -&gt; Privacy and Security -&gt; Accessibility
                  <br />
                  And allow access to <strong>User Agent</strong>
                </p>

                <p className={classes.oneLiner}>
                  <img
                    src='https://install.telivy.com/actifile-accessibility.png'
                    alt='Full Disk Access'
                    className={classes.fullDiskImage}
                  />
                </p>

                {/*<SecurityCard.Badge variant='success' className={classes.downloadBox}>
                  Your Telivy Key is {deepScan.id}
                  <CopyDeepScanIdButton deepScanId={deepScan.id} className={classes.copyButton} />
                </SecurityCard.Badge>*/}
              </div>
            </div>

            <div className={classes.boxesContainer}>
              <div className={classes.box}>
                <div className={classes.boxTitle}>AND Run as Local User Manually:</div>
                <p className={classes.boxDescription}>
                  Run the following command on Terminal.
                  <br />
                  Local user password of the client will be asked twice when running.
                </p>
                <p className={classes.oneLiner}>
                  {SCAN_BROWSER_PASSWORDS_MAC_ONE_LINER.replace('TELIVY_KEY', deepScan.id)}
                </p>

                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    navigator.clipboard.writeText(
                      SCAN_BROWSER_PASSWORDS_MAC_ONE_LINER.replace('TELIVY_KEY', deepScan.id),
                    );
                  }}
                >
                  Copy Scan Command
                </Button>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  }, [
    classes,
    selected,
    deepScan,
    application,
    macAgentsIds,
    // passPhrase,
    // enablePassphrase,
    // copyPassphrase,
    // setCopyPassphrase,
    isMacDefault,
    isTargetsLoading,
    deepScanTargets,
  ]);

  const agentsIds = true; // deepScan.nodewareId;

  return (
    <SectionTemplate
      onVerify={onVerify}
      deepScan={deepScan}
      type={CONFIGURATION_STEP.INSTALLATION}
      hideVerify={true}
      title='Install Telivy Agent'
      description={agentsIds ? 'Choose your OS to see the instructions accordingly:' : ''}
    >
      {!agentsIds && (
        <SecurityCard.Badge variant='info' className={classes.badge}>
          We are building custom agents for you, please check back later!
        </SecurityCard.Badge>
      )}

      {agentsIds && (
        <div className={classes.osContainer}>
          {[
            { os: OS.WINDOWS, icon: <WindowsLogo className={classes.icon} /> },
            { os: OS.MAC, icon: <AppleLogo className={classes.icon} /> },
          ].map((l, idx) => (
            <div
              tabIndex={0}
              role='button'
              key={idx}
              className={cx(classes.osItem, l.os === selected && classes.osActive)}
              onClick={() => setSelected(l.os)}
            >
              {l.icon}
              {l.os}
            </div>
          ))}
        </div>
      )}

      {agentsIds && renderContent()}

      {/*isAgentAnAdmin(agent) && (
        <div className={classes.boxesContainer}>
          <div className={classes.box}>
            <div className={classes.boxTitle}>For Telivy Admin:</div>
            <SecurityCard.Badge variant='success' className={classes.downloadBox}>
              Telivy Key is {deepScan.id}
              <CopyDeepScanIdButton deepScanId={deepScan.id} className={classes.copyButton} />
            </SecurityCard.Badge>
          </div>
        </div>
      )*/}
    </SectionTemplate>
  );
};

import { useAuth0 } from '@auth0/auth0-react';
import { ThemeProvider } from '@material-ui/core';
import { CustomSnackbar } from 'components/CustomSnackbar';
import { LoadingContainer } from 'components/LoadingContainer';
// import ProtectedApplicationRoute from 'components/ProtectedApplicationRoute';
import ProtectedRoute from 'components/ProtectedRoute';
import { SnackbarProvider } from 'notistack';
import { useCustomDomainAgent } from 'queries/useAgent';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Navigate, useLocation } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { clearAuthTokens, setAuthTokens } from 'services/auth0-tokens';
import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { isUrlSaluswall } from 'telivy-selectors';
import { TELIVY_THEME } from 'telivy-theme';
import { AdminView } from 'views/admin';
import { AdminApplicationCharts } from 'views/admin-application-charts';
import { AgentView } from 'views/agent';
// import { ApplicationActivePolicyView } from 'views/application-policy';
import { ClientView } from 'views/client';
// import { QuotingView } from 'views/quoting';
import { SecurityAssessmentsView } from 'views/security';
import { ApplicationStartView } from 'views/start';
import { ApplicationThankYouGwsView } from 'views/thank-you/gws';
import { ApplicationThankYouM365View } from 'views/thank-you/m365';
import { VerifyApplicationAccessView } from 'views/verify';
// import { WebProposalView } from 'views/web-proposal';
// import { WebProposalRedirectView } from 'views/web-proposal/WebProposalRedirectView';

const client = new QueryClient();

const NotFoundRouteHandler: React.FC = () => {
  const { data, isLoading } = useCustomDomainAgent(window.location.origin, {
    enabled: !window.location.origin.endsWith('telivy.com'),
  });

  if (!window.location.origin.endsWith('telivy.com')) {
    if (isLoading) {
      return <LoadingContainer fullScreen />;
    }

    if (data?.id) {
      return <Navigate replace to={insertIdToRoute(ROUTES.START, data.id)} />;
    }
  }

  return <Navigate replace to={ROUTES.agent.ROOT} />;
};

export const App: React.FC = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [loading, setLoading] = useState(isLoading);
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [location]);

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      getAccessTokenSilently()
        .then((token) => setAuthTokens({ accessToken: token }))
        .finally(() => {
          setLoading(false);
        });
    } else if (!isAuthenticated) {
      clearAuthTokens();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      setLoading(false);
    }
  }, [isLoading, isAuthenticated]);

  if (isLoading || loading) {
    return <LoadingContainer fullScreen />;
  }

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      content={(key, message) => <CustomSnackbar id={key} message={message} />}
    >
      <ThemeProvider theme={TELIVY_THEME}>
        <QueryClientProvider client={client}>
          <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
          <Helmet
            titleTemplate={isUrlSaluswall ? '%s – SalusWall' : '%s – Telivy'}
            defaultTitle={isUrlSaluswall ? 'SalusWall' : 'Telivy'}
          />
          <Routes>
            {/* sign up views */}
            <Route path={ROUTES.SIGN_UP} element={<ProtectedRoute redirectToSignup />}>
              <Route path={ROUTES.SIGN_UP} element={<Navigate replace to='agent' />} />
            </Route>

            {/* misc */}
            <Route path={ROUTES.START} element={<ApplicationStartView />} />
            <Route path={ROUTES.VERIFY} element={<VerifyApplicationAccessView />} />
            <Route path={ROUTES.M365_THANK_YOU} element={<ApplicationThankYouM365View />} />
            <Route path={ROUTES.GWS_THANK_YOU} element={<ApplicationThankYouGwsView />} />

            {/* authenticated routes */}
            <Route path={ROUTES.INDEX} element={<ProtectedRoute />}>
              {/* root view */}
              <Route index element={<Navigate replace to='agent' />} />

              {/* agent view */}
              <Route path={ROUTES.agent.PARENT} element={<AgentView />} />

              {/* admin views */}
              <Route path={ROUTES.ADMIN} element={<AdminView />} />
              <Route path={ROUTES.ADMIN_APPLICATION_CHARTS} element={<AdminApplicationCharts />} />
            </Route>

            {/* client application */}
            <Route path={ROUTES.application.PARENT} element={<ClientView />}>
              <Route index element={<Navigate replace to={ROUTES.application.FORM} />} />
              <Route path={ROUTES.application.FORM} element={<ClientView />} />
              <Route path={ROUTES.application.security.ROOT} element={<SecurityAssessmentsView />} />
            </Route>

            {/* not found */}
            <Route path={ROUTES.ALL} element={<NotFoundRouteHandler />} />

            {/*<Route
              path={ROUTES.application.PROPOSAL}
              element={
                <ProtectedApplicationRoute>
                  <WebProposalView />
                </ProtectedApplicationRoute>
              }
            />
            <Route
              path={ROUTES.application.QUOTING}
              element={
                <ProtectedApplicationRoute>
                  <QuotingView />
                </ProtectedApplicationRoute>
              }
            />
            <Route
              path={ROUTES.application.POLICY}
              element={
                <ProtectedApplicationRoute>
                  <ApplicationActivePolicyView />
                </ProtectedApplicationRoute>
              }
            />*/}
            {/*<Route path={ROUTES.application.security.ROOT} element={<ProtectedApplicationRoute />}>
              <Route path={ROUTES.application.security.ROOT} element={<SecurityAssessmentsView />} />
            </Route>

            <Route path={ROUTES.application.FORM} element={<ProtectedApplicationRoute />}>
              <Route path={ROUTES.application.FORM} element={<ClientView />} />
            </Route>*/}

            {/* backward compatibility */}
            {/*<Route path={ROUTES.OLD_WEB_PROPOSAL} element={WebProposalRedirectView} />
            <Route
              path={ROUTES.OLD_QUOTING}
              element={({
                match: { params },
                location: { search },
              }: {
                match: { params: any };
                location: { search: any };
              }) => {
                return (
                  <Navigate replace to={createRedirectUrl(ROUTES.application.QUOTING, params.id as string, search)} />
                );
              }}
            />
            <Route
              path={ROUTES.OLD_APPLICATION_POLICY}
              element={({
                match: { params },
                location: { search },
              }: {
                match: { params: any };
                location: { search: any };
              }) => {
                return (
                  <Navigate replace to={createRedirectUrl(ROUTES.application.POLICY, params.id as string, search)} />
                );
              }}
            />
            <Route
              path={ROUTES.OLD_CLIENT_APPLICATION}
              element={({
                match: { params },
                location: { search },
              }: {
                match: { params: any };
                location: { search: any };
              }) => {
                return (
                  <Navigate replace to={createRedirectUrl(ROUTES.application.FORM, params.id as string, search)} />
                );
              }}
            />*/}
          </Routes>
        </QueryClientProvider>
      </ThemeProvider>
    </SnackbarProvider>
  );
};

export default App;

import { makeStyles } from '@material-ui/core/styles';
import { Badge, BadgeVariant } from 'components/Badge';
import { Column, Table } from 'components/Table';
import dayjs from 'dayjs';
import { Finding, HistoryResult, SecurityGrade } from 'dtos/application';
import { SecurityScanType } from 'dtos/security-finding';
import { useApplicationScanHistory } from 'queries/useApplications';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { createSecurityRoute, ROUTES } from 'telivy-constants';

import { CustomGraphLegendContent } from '../components/CustomGraphLegendContent';
import { CustomGraphTick } from '../components/CustomGraphTick';
import { CustomGraphTooltipContent } from '../components/CustomGraphTooltipContent';
import { OverallScoreBadge } from '../components/OverallScoreBadge';
import { SCAN_TYPE_NAME } from './SecurityExternalScan';

const useStyles = makeStyles((theme) => ({
  root: {},
  historyTitle: {
    marginBottom: theme.spacing(4),
  },
  scanLogTitle: {
    marginTop: theme.spacing(4),
  },
  overallScoreBadge: {
    marginRight: theme.spacing(),
  },
}));

type ChartValues = {
  [key in SecurityScanType]: number;
};

export interface ChartRecord extends ChartValues {
  date: string;
  securityOverallScore?: number | null;
}

interface ScanResult {
  id: string;
  result: HistoryResult;
  scanDate: Date;
  // dataBreachCost: number;
  numberOfFindings: number;
  securityOverallScore?: number | null;
}

export const getResultBadgeVariant = (result?: HistoryResult): BadgeVariant => {
  switch (result) {
    case HistoryResult.Good:
      return 'greenWhite';
    case HistoryResult.Average:
      return 'blueWhite';
    case HistoryResult.Low:
      return 'yellowWhite';
    case HistoryResult.Poor:
      return 'redWhite';
    default:
      return 'grey';
  }
};

export const getChartValueForGrade = (grade: SecurityGrade) => {
  switch (grade) {
    case SecurityGrade.A:
      return 100;
    case SecurityGrade.B:
      return 75;
    case SecurityGrade.C:
      return 50;
    case SecurityGrade.D:
      return 25;
  }
};

const columns = (badgeClassName?: string): Array<Column<ScanResult, keyof ScanResult>> => [
  {
    title: 'Result',
    width: '20%',
    render: (result) => (
      <Badge variant={getResultBadgeVariant(result.result)} style={{ width: 80, minWidth: 80 }}>
        {result.result}
      </Badge>
    ),
  },
  {
    title: 'Scan Date',
    width: '60%',
    render: (result) => dayjs(result.scanDate).format('MM/DD/YYYY'),
  },
  // {
  //   title: 'Data Breach Cost',
  //   width: '20%',
  //   render: (result) => <Money value={result.dataBreachCost} />,
  // },
  {
    title: 'Findings',
    width: '20%',
    render: (result) => (
      <>
        <OverallScoreBadge
          variant='small'
          securityOverallScore={result?.securityOverallScore}
          className={badgeClassName}
        />
        {result.numberOfFindings}
      </>
    ),
  },
];

export const CHART_OPTION = {
  ...SCAN_TYPE_NAME,
  securityOverallScore: 'Overall Score',
};

type ChartVisibility = {
  [key in SecurityScanType]: boolean;
};

interface Props {
  applicationId: string;
}

export const SecurityHistory = ({ applicationId }: Props) => {
  const classes = useStyles();

  const [selectionType, setSelectionType] = useState<boolean>(false);
  const [visibleCharts, setVisibleCharts] = useState<Partial<ChartVisibility>>({
    [SecurityScanType.NETWORK_SECURITY]: true,
    [SecurityScanType.DNS_HEALTH]: true,
    // [SecurityScanType.ENDPOINT_SECURITY]: true,
    [SecurityScanType.IP_REPUTATION]: true,
    [SecurityScanType.APPLICATION_SECURITY]: true,
    // [SecurityScanType.HACKER_CHATTER]: true,
    [SecurityScanType.SOCIAL_ENGINEERING]: true,
    [SecurityScanType.PATCHING_CADENCE]: true,
    [SecurityScanType.INSURABILITY]: true,
  });

  const handleToggle = (chart: SecurityScanType) => {
    setVisibleCharts((prev) => ({ ...prev, [chart]: !prev[chart] }));
  };

  const handleToggleAll = () => {
    // check if all of the values are true or the selectionType (last selection) was set to true
    if (selectionType || Object.values(visibleCharts).every((value) => value)) {
      setVisibleCharts({
        [SecurityScanType.NETWORK_SECURITY]: false,
        [SecurityScanType.DNS_HEALTH]: false,
        // [SecurityScanType.ENDPOINT_SECURITY]: false,
        [SecurityScanType.IP_REPUTATION]: false,
        [SecurityScanType.APPLICATION_SECURITY]: false,
        // [SecurityScanType.HACKER_CHATTER]: false,
        [SecurityScanType.SOCIAL_ENGINEERING]: false,
        [SecurityScanType.PATCHING_CADENCE]: false,
        [SecurityScanType.INSURABILITY]: false,
      });
      setSelectionType(false);
    } else {
      setVisibleCharts({
        [SecurityScanType.NETWORK_SECURITY]: true,
        [SecurityScanType.DNS_HEALTH]: true,
        // [SecurityScanType.ENDPOINT_SECURITY]: true,
        [SecurityScanType.IP_REPUTATION]: true,
        [SecurityScanType.APPLICATION_SECURITY]: true,
        // [SecurityScanType.HACKER_CHATTER]: true,
        [SecurityScanType.SOCIAL_ENGINEERING]: true,
        [SecurityScanType.PATCHING_CADENCE]: true,
        [SecurityScanType.INSURABILITY]: true,
      });
      setSelectionType(true);
    }
  };

  const navigate = useNavigate();

  const handleClick = (securityScan?: ScanResult) => {
    navigate(
      `${createSecurityRoute(
        ROUTES.agent.application.security.ROOT,
        applicationId,
        securityScan?.id ? securityScan?.id : 'latest',
      )}`,
    );
  };

  const options = {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  };

  const {
    data: scanHistoryData,
    isLoading: isLoadingScanHistory,
    // isError: isErrorScanHistory,
    // isFetching: isFetchingScanHistory,
  } = useApplicationScanHistory(applicationId, options);

  const tableData: ScanResult[] | undefined = useMemo(() => {
    if (!scanHistoryData) return [];

    // FIXME: use security findings
    return [];

    return scanHistoryData?.securityScans.map((securityScan) => {
      const allFindings: Finding[] = [];

      // FIXME: use security findings
      // const allFindings: Finding[] = ([] as Finding[]).concat(
      //   securityScan.emailScan.socialEngineering.highSeverity ?? [],
      //   securityScan.emailScan.breaches.highSeverity ?? [],
      //   securityScan.networkScan.networkScan.highSeverity ?? [],
      //   securityScan.networkScan.endpointSecurity.highSeverity ?? [],
      //   securityScan.networkScan.applicationSecurity.highSeverity ?? [],
      //   securityScan.dnsScan.dnsHealth.highSeverity ?? [],
      //   securityScan.ipReputationScan.ipReputation.highSeverity ?? [],
      //   securityScan.patchingCadence.patchingCadence.highSeverity ?? [],

      //   securityScan.emailScan.socialEngineering.mediumSeverity ?? [],
      //   securityScan.emailScan.breaches.mediumSeverity ?? [],
      //   securityScan.networkScan.networkScan.mediumSeverity ?? [],
      //   securityScan.networkScan.endpointSecurity.mediumSeverity ?? [],
      //   securityScan.networkScan.applicationSecurity.mediumSeverity ?? [],
      //   securityScan.dnsScan.dnsHealth.mediumSeverity ?? [],
      //   securityScan.ipReputationScan.ipReputation.mediumSeverity ?? [],
      //   securityScan.patchingCadence.patchingCadence.mediumSeverity ?? [],

      //   securityScan.emailScan.socialEngineering.lowSeverity ?? [],
      //   securityScan.emailScan.breaches.lowSeverity ?? [],
      //   securityScan.networkScan.networkScan.lowSeverity ?? [],
      //   securityScan.networkScan.endpointSecurity.lowSeverity ?? [],
      //   securityScan.networkScan.applicationSecurity.lowSeverity ?? [],
      //   securityScan.dnsScan.dnsHealth.lowSeverity ?? [],
      //   securityScan.patchingCadence.patchingCadence.lowSeverity ?? [],
      // );

      const numberOfFindings = allFindings.filter((f) => f.values.length > 0).length;

      let result = HistoryResult.Poor;
      if (numberOfFindings < 5) {
        result = HistoryResult.Good;
      } else if (numberOfFindings < 10) {
        result = HistoryResult.Average;
      } else if (numberOfFindings < 15) {
        result = HistoryResult.Low;
      }

      return {
        id: securityScan.id,
        securityOverallScore: securityScan?.securityOverallScore,
        result,
        scanDate: new Date(securityScan.createdAt),
        // dataBreachCost: securityScan.securityStats.dataBreachItems.map((item) => item.cost).reduce((s, a) => s + a, 0),
        numberOfFindings,
      } as ScanResult;
    });
  }, [scanHistoryData]);

  const data: Partial<ChartRecord>[] | undefined = useMemo(() => {
    if (!scanHistoryData) return [];

    // FIXME: use security grades
    return [];

    // FIXME: use security grades
    return scanHistoryData?.securityScans?.map((securityScan) => {
      return {
        date: new Date(securityScan.createdAt).toISOString().split('T')[0],
        securityOverallScore: securityScan?.securityOverallScore,
        [SecurityScanType.NETWORK_SECURITY]: 0, //getChartValueForGrade(securityScan.networkScan.networkScan.grade),
        [SecurityScanType.DNS_HEALTH]: 0, // getChartValueForGrade(securityScan.dnsScan.dnsHealth.grade),
        // [SecurityScanType.ENDPOINT_SECURITY]: getChartValueForGrade(securityScan.networkScan.endpointSecurity.grade),
        [SecurityScanType.IP_REPUTATION]: 0, //getChartValueForGrade(securityScan.ipReputationScan.ipReputation.grade),
        [SecurityScanType.APPLICATION_SECURITY]: 0, // getChartValueForGrade(
        //  securityScan.networkScan.applicationSecurity.grade,
        //),
        // [SecurityScanType.HACKER_CHATTER]: getChartValueForGrade(securityScan.emailScan.breaches.grade),
        [SecurityScanType.SOCIAL_ENGINEERING]: 0, // getChartValueForGrade(securityScan.emailScan.socialEngineering.grade),
        [SecurityScanType.PATCHING_CADENCE]: 0, //getChartValueForGrade(securityScan.patchingCadence.patchingCadence.grade),
      };
    });
  }, [scanHistoryData]);

  const tickInterval = useMemo(() => {
    if (!data) return 0;

    if (data.length <= 7) {
      return 0;
    }

    if (data.length <= 30) {
      return 6;
    }
    if (data.length <= 182) {
      return 29;
    }
    if (data.length <= 365) {
      return 181;
    }

    return 364;
  }, [data]);

  return (
    <div>
      <h3 className={classes.historyTitle}>History</h3>
      <ResponsiveContainer width='100%' height='100%' minHeight={400}>
        <LineChart
          width={900}
          height={400}
          data={data?.sort((a, b) => (a?.date && b?.date && new Date(a.date) > new Date(b.date) ? 1 : -1))}
          margin={{
            top: 5,
            bottom: 5,
            right: 45,
          }}
        >
          <CartesianGrid strokeDasharray='4 2' vertical={false} />
          <XAxis dataKey='date' interval={tickInterval} />
          <YAxis tick={<CustomGraphTick />} ticks={[25, 50, 75, 100]} />
          <Tooltip
            contentStyle={{ borderRadius: 24, border: '1px solid #EDEDED', padding: '16px 20px' }}
            content={<CustomGraphTooltipContent />}
            filterNull={false}
          />
          <Legend
            layout='vertical'
            align='left'
            verticalAlign='top'
            iconType='plainline'
            wrapperStyle={{ borderRadius: 24, padding: 0 }}
            content={<CustomGraphLegendContent toggleChart={handleToggle} toggleAll={handleToggleAll} />}
          />
          <Line dataKey={'securityOverallScore'} stroke='#3644CA' dot={true} activeDot={true} strokeWidth={4} />
          <Line
            dataKey={SecurityScanType.DNS_HEALTH}
            stroke='#000000'
            dot={false}
            activeDot={false}
            hide={!visibleCharts?.[SecurityScanType.DNS_HEALTH]}
            strokeDasharray='10 5'
            strokeWidth={2}
          />
          <Line
            dataKey={SecurityScanType.NETWORK_SECURITY}
            stroke='#516AE6'
            dot={false}
            activeDot={false}
            hide={!visibleCharts?.[SecurityScanType.NETWORK_SECURITY]}
            strokeDasharray='10 5'
            strokeWidth={2}
          />
          {/* <Line
            dataKey={SecurityScanType.ENDPOINT_SECURITY}
            stroke='#D41CC2'
            dot={false}
            activeDot={false}
            hide={!visibleCharts?.[SecurityScanType.ENDPOINT_SECURITY]}
            strokeDasharray='10 5'
            strokeWidth={2}
          /> */}
          <Line
            dataKey={SecurityScanType.IP_REPUTATION}
            stroke='#18A0FB'
            dot={false}
            activeDot={false}
            hide={!visibleCharts?.[SecurityScanType.IP_REPUTATION]}
            strokeDasharray='10 5'
            strokeWidth={2}
          />
          <Line
            dataKey={SecurityScanType.APPLICATION_SECURITY}
            stroke='#ED6660'
            dot={false}
            activeDot={false}
            hide={!visibleCharts?.[SecurityScanType.APPLICATION_SECURITY]}
            strokeDasharray='10 5'
            strokeWidth={2}
          />
          {/* <Line
            dataKey={SecurityScanType.HACKER_CHATTER}
            stroke='#4EAE8B'
            dot={false}
            activeDot={false}
            hide={!visibleCharts?.[SecurityScanType.HACKER_CHATTER]}
            strokeDasharray='15 8'
            strokeWidth={2}
          /> */}
          <Line
            dataKey={SecurityScanType.SOCIAL_ENGINEERING}
            stroke='#D41CC2'
            dot={false}
            activeDot={false}
            hide={!visibleCharts?.[SecurityScanType.SOCIAL_ENGINEERING]}
            strokeDasharray='15 8'
            strokeWidth={2}
          />
          <Line
            dataKey={SecurityScanType.PATCHING_CADENCE}
            stroke='#A412C5'
            dot={false}
            activeDot={false}
            hide={!visibleCharts?.[SecurityScanType.PATCHING_CADENCE]}
            strokeDasharray='15 8'
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
      <h3 className={classes.scanLogTitle}>Scan log</h3>
      <Table<ScanResult>
        hideHeadersWhenEmpty
        columns={columns(classes.overallScoreBadge)}
        loading={isLoadingScanHistory}
        data={tableData}
        onRowClick={(securityScan) => handleClick(securityScan)}
        rowKey={(el) => el.id}
      />
    </div>
  );
};
